<template>
    <div>
        <h6>update serie a table</h6>
        <input @click="updateTable" type="submit" value="update table" class="button_submit">
    </div>
    <div>
        <h6>update milan's latest match(1 match only)</h6>
        <input @click="updateLastMatch" type="submit" value="update next Match" class="button_submit">
    </div>
    <div>
        <h6>update fixtures and result('deletes everything and updates it all')</h6>
        <input @click="updateFixtures1" type="submit" value="update fixtures1" class="button_submit">
        <input @click="updateFixtures2" type="submit" value="update fixtures2" class="button_submit">
        <input @click="updateFixtures3" type="submit" value="update fixtures3" class="button_submit">
    </div>
    <div>
        <h6>update serie a weekly results</h6>
        <input @click="updateWeeklyResults" type="submit" value="serie a weekly1" class="button_submit">
        <input @click="updateWeeklyResults2" type="submit" value="serie a weekly2" class="button_submit">
        <input @click="updateWeeklyResults3" type="submit" value="serie a weekly3" class="button_submit">
    </div>

    <div>
        <h6>update serie a Goalscorers</h6>
        <input @click="updateGoalscorers" type="submit" value="update serie a Goalscorers" class="button_submit">
    </div>
    <div>
        <h6>update ucl groups weekly results</h6>

        <input @click="updateUclWeeklyResults1" type="submit" value="ucl g" class="button_submit">
        <input @click="updateUclWeeklyResults2" type="submit" value="ucl g2" class="button_submit">
        <input @click="updateUclWeeklyResults3" type="submit" value="ucl g3" class="button_submit">
    </div>
    <div>
        <h6>update uel groups weekly results</h6>
        <input @click="updateUelWeeklyResults" type="submit" value="update uel Groups weekly results"
            class="button_submit">
    </div>
    <div>
        <h6>update ucl knockout weekly results</h6>
        <input @click="updateUclWeeklyResultsKnockout" type="submit" value="ucl knockout1" class="button_submit">
        <input @click="updateUclWeeklyResultsKnockout2" type="submit" value="ucl knockout2" class="button_submit">
        <input @click="updateUclWeeklyResultsKnockout3" type="submit" value="ucl knockout3" class="button_submit">
    </div>
    <div>
        <h6>update uel knockout weekly results</h6>
        <input @click="updateUelWeeklyResultsKnockout" type="submit" value="uel knockout1" class="button_submit">
        <input @click="updateUelWeeklyResultsKnockout2" type="submit" value="uel knockout2" class="button_submit">
        <input @click="updateUelWeeklyResultsKnockout3" type="submit" value="uel knockout3" class="button_submit">
    </div>
    <div>
        <h6>update ucl Goalscorers</h6>
        <input @click="updateUclGoalscorers" type="submit" value="update ucl Goalscorers" class="button_submit">
    </div>
    <div>
        <h6>update uel Goalscorers</h6>
        <input @click="updateUelGoalscorers" type="submit" value="update uel Goalscorers" class="button_submit">
    </div>
    <div>
        <h6>update ucl group tables</h6>
        <input @click="updateUclTables" type="submit" value="update ucl tables" class="button_submit">
    </div>
    <div>
        <h6>update uel group tables</h6>
        <input @click="updateUelTables" type="submit" value="update uel tables" class="button_submit">
    </div>
</template>

<script>
export default {
    name: 'ResultVue',
    inject: ['backendServer', 'currentHost', 'setFlashedMessage', 'loading'],

    methods: {
        updateTable() {
            this.loading()

            fetch(this.backendServer() + '/table-update', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    this.setFlashedMessage(data);
                });
        },
        updateUclTables() {
            this.loading()

            fetch(this.backendServer() + '/cl-groups', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    this.setFlashedMessage(data);
                });
        },
        updateUelTables() {
            this.loading()

            fetch(this.backendServer() + '/uel-groups', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    this.setFlashedMessage(data);
                });
        },
        updateLastMatch() {
            this.loading()
            fetch(this.backendServer() + '/update-latest-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    this.setFlashedMessage(data);
                });
        },
        updateWeeklyResults() {
            this.loading()
            fetch(this.backendServer() + '/update-weekly-results', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateWeeklyResults2() {
            this.loading()
            fetch(this.backendServer() + '/all-league-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateWeeklyResults3() {
            this.loading()
            fetch(this.backendServer() + '/concat-league-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUclWeeklyResults1() {
            this.loading()
            fetch(this.backendServer() + '/update-weekly-results-ucl1', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUclWeeklyResults2() {
            this.loading()
            fetch(this.backendServer() + '/update-weekly-results-ucl2', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUclWeeklyResults3() {
            this.loading()
            fetch(this.backendServer() + '/update-weekly-results-ucl3', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUclWeeklyResultsKnockout() {
            this.loading()
            fetch(this.backendServer() + '/update-ucl16-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUclWeeklyResultsKnockout2() {
            this.loading()
            fetch(this.backendServer() + '/all-cl-league-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUclWeeklyResultsKnockout3() {
            this.loading()
            fetch(this.backendServer() + '/concat-ucl-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUelWeeklyResults() {
            this.loading()
            fetch(this.backendServer() + '/update-weekly-results-uel', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUelWeeklyResultsKnockout() {
            this.loading()
            fetch(this.backendServer() + '/update-uel16-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUelWeeklyResultsKnockout2() {
            this.loading()
            fetch(this.backendServer() + '/all-ul-league-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUelWeeklyResultsKnockout3() {
            this.loading()
            fetch(this.backendServer() + '/concat-uel-matches', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateFixtures1() {
            this.loading()
            fetch(this.backendServer() + '/update-fixtures-and-results1', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateFixtures2() {
            this.loading()
            fetch(this.backendServer() + '/update-fixtures-and-results2', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateFixtures3() {
            this.loading()
            fetch(this.backendServer() + '/update-fixtures-and-results3', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateGoalscorers() {
            this.loading()
            fetch(this.backendServer() + '/goalscorer-update', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUclGoalscorers() {
            this.loading()
            fetch(this.backendServer() + '/ucl-goalscorer-update', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        },
        updateUelGoalscorers() {
            this.loading()
            fetch(this.backendServer() + '/uel-goalscorer-update', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    if (data['error']) {
                        this.setFlashedMessage(data['error']);
                    }
                    else
                        this.setFlashedMessage(data['message']);
                });
        }

    },

}
</script>

<style scoped>
div {
    text-align: center;
}
</style>