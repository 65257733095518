<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <!-- <div v-if="$route.path.includes('/schedule')" class="sub_container_b">
            <NewsSubMenu name="Fixtures" route="fixtures" />
            <NewsSubMenu name="Results" route="results" />
            <NewsSubMenu name="Squad" route="squad" />

        </div> -->
        <ul class=" fixtures">
            <FixturesVue v-for="(game, index) in games " :key="index" :game="game" />
        </ul>

    </main>
</template>

<script>
import ArticleLogo from '@/components/ArticleLogo.vue'
import FixturesVue from '@/components/team/FixturesVue.vue'
// import NewsSubMenu from '@/components/team/NewsSubMenu.vue'

export default {
    name: 'MilanFixtures',
    components: { ArticleLogo, FixturesVue },
    data() {
        return {
            games: null
        }
    },
    inject: ['backendServer', 'currentHost'],

    methods: {
        activeMenuChange(event) {
            this.activeMenu = String(event)

        },
        activeSubMenuChange(event) {
            this.activeSubMenu = String(event)

        }
    },
    mounted() {
        document.title = 'Fixtures / Rossoneries';

        fetch(this.backendServer() + '/next-games', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {
                this.games = data;

            });

    }

}
</script>

<style>
@media screen and (max-width:380px) {
    main.news ul.fixtures {
        width: 98% !important;

    }

    form.login_form {
        text-align: center;
        width: 310px !important;
        border-right: 1px solid red;
        border-left: 1px solid red;

    }

}

@media screen and (max-width:850px) {
    main.news ul.fixtures {
        width: 90%;
        display: block;
    }

}



ul.fixtures {
    width: 90%;
    margin: 5px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

ul.fixtures li {
    background-color: rgb(255, 255, 255);
    margin-top: 0 !important;
    list-style: none;
    border-radius: 10px;
    padding: 0 5px;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 2px 0;
    overflow: hidden;
    transition: all .3s linear;
    height: auto;
}

/* ul.fixtures li:first-child {
    flex: 0 0 100%;
    height: 110px
} */

div.match-title {
    display: flex;
    justify-content: space-between;
    height: 30px;
}

span.match-d {

    flex: 20%;
    text-align: left;
    padding-left: 5px;
    font-size: 0.8em;
}

span.c-name {
    font-size: .8em;
}

span.spacer {
    flex: 20%;
    text-align: right;
}

.spacer svg {
    margin-right: 4px;
    margin-top: -4px;
    cursor: pointer;
}
</style>