<template>
    <div class="table-container">


        <li :class="[fullScreen ? 'standings sr' : '', single ? 'single' : 'seriaa'].join(' ')">
            <!-- <div v-if="fullScreen" class="select-year">
                <select @change="sorting" name="" id="" class="year_options">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
            </div> -->
            <div v-if="!fullScreen" class="league_player">
                <h6 class="league-info-header">Serie A Table</h6>

            </div>
            <table class="seriaa" :class="fullScreen && 'standings'">
                <thead>
                    <tr>

                        <th colspan="2" style="padding-left: 5px;">{{ home ? 'Serie A Standings' : 'club' }}</th>

                        <th>M</th>
                        <th>W</th>
                        <th>D</th>
                        <th>L</th>
                        <th>Gls</th>
                        <th>Gd</th>
                        <th>pts</th>

                    </tr>
                </thead>

                <tbody v-if="table">
                    <tr v-for="(team, index) in table" :key="index" class="teams" :class="checkTeamResult(team.name)">
                        <td :class="[index + 1 < 5
            ? 'cl_qualify'
            : (index + 1 == 5
                ? 'ul_qualify'
                : (index + 1 == 6
                    ? 'con_qualify'
                    : (index + 1 > 17
                        ? ' relegate'
                        : 'rest')))]">
                            <div></div>
                        </td>
                        <td class="team"> <span>{{ index + 1 }}</span> - <img :src="team.logo" alt=""> {{ team.name }}
                        </td>
                        <td>{{ team.wins + team.draws + team.losses }}</td>
                        <td>{{ team.wins }}</td>
                        <td>{{ team.draws }}</td>
                        <td>{{ team.losses }}</td>
                        <td>{{ team.goals_scored }}:{{ team.goals_con }}</td>
                        <td>{{ team.gd }}</td>
                        <td>{{ team.points }}</td>
                    </tr>
                </tbody>

            </table>
            <div v-if="!fullScreen" class="seriaa_full_table">
                <a href="">More</a>
            </div>
            <div v-if="fullScreen" class="standings_info">
                <div class="standing_container">
                    <div class="cl_qualify"></div>UEFA Champions League group stage
                </div>
                <div class="standing_container">
                    <div class="ul_qualify"></div>Europa League group stage
                </div>
                <div class="standing_container">
                    <div class="con_qualify"></div>Europa Conference League qualifiers
                </div>
                <div class="standing_container">
                    <div class="relegate"></div>Relegation
                </div>

            </div>

        </li>
    </div>
</template>

<script>
export default {
    name: 'TableNews',
    inject: ['backendServer', 'currentHost', 'loading'],
    props: ['fullScreen', 'limit', 'currentYear', 'single', 'home', 'away'],
    data() {
        return {
            table: null,
            years: null,
            year: null
        }
    },
    methods: {

        fetchTable() {
            // if (!this.currentYear) {
            //     this.currentYear == 'default'
            // }
            this.loading()
            fetch(this.backendServer() + '/serie-a/' + this.currentYear, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.table = data.teams;
                    this.years = data.years
                    this.$emit('years', this.years)

                    this.loading()
                });
        },
        checkTeamResult(team) {
            if (!this.home) {
                return
            }
            if (this.home == team || this.away == team) {
                return 'highlight-team'
            }
        }
    },
    mounted() {
        this.fetchTable()


    },
    watch: {
        currentYear: function () { // watch it
            this.fetchTable()
        }
    }
}
</script>

<style>
.highlight-team {
    background-color: rgb(160 177 255 / 42%);
}

li.seriaa {
    margin-bottom: 2px !important;
}

select.year_options {
    border: none;
    border-radius: 7px;
    outline: none;
    margin-left: 5px;
    background-color: bisque;
}

select.year_options:focus-visible {
    border: none;
}

div.table-container {
    display: block;
    width: 100%;
}

div.select-year {
    position: absolute;
    right: 5px;
    top: 5px;
}

li.tablenews {
    margin: 0 !important;
    height: fit-content !important;
    margin-top: 30px !important;
    position: relative;
}

td.team {
    text-transform: capitalize;
    display: flex;
}

td.team span {
    min-width: 16px;
}

li.ucl td.team span {
    min-width: 10px;
}

img {
    padding: 0 !important;
}

.single {
    border-radius: 0 !important;
}
</style>