export function changeStage(name) {

    switch (name) {
        case '1/16 final':
            return 11
        case '1/16 Final':
            return 11
        case '1/8 final':
            return 12;
        case '1/8 Final':
            return 12;
        case 'QUARTER-FINALS':
            return 13;
        case 'semi final':
            return 9;
        case 'SEMI-FINALS':
            return 9;
        case 'final':
            return 10;
        case 'Final':
            return 10;
        default:
            return name;
    }
}
