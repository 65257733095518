<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo :logo="logo" />
        <div v-if="$route.path.includes('/serie-a')" :class="$route.path.includes('/serie-a') && 'sub_container_small'"
            class="sub_container_b">
            <NewsSubMenu name="Info" route="leagueinfo" />
            <NewsSubMenu name="Table" route="leaguetable" />
            <NewsSubMenu name="Matchday" route="matchday" />
            <NewsSubMenu name="Goal-Scorers" route="serieags" />

        </div>
        <div class="comp_title comp_title_matchday">
            <h5 class="league-info-header">{{ title }}</h5>
            <select @change="sorting" name="" id="" class="year_options">
                <option v-for="year in allyears" :key="year" :value="year">{{ year }}</option>
            </select>
        </div>
        <ul class="league_info">
            <div class="info_table">
                <TableNews v-if="activeSubMenu == 'table'" :fullScreen="fullScreen" :currentYear="currentYear"
                    @years="years" />
                <SwappedTeams :currentYear="currentYear" />
            </div>
            <div class="info_extra">
                <GoalScorers v-if="activeSubMenu == 'table'" :limit="3" :currentYear="currentYear" key="currentYear" />
                <WeeklyResults :currentYear="currentYear" />
                <!-- <LastWeekResult v-if="currentYear == '2023-24' || currentYear == 'default'" /> -->
            </div>
        </ul>

    </main>
</template>

<script>
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
import TableNews from '@/components/team/TableNews.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import GoalScorers from '@/components/team/GoalScorers.vue'
import WeeklyResults from '@/components/team/WeeklyResults.vue'
import SwappedTeams from '@/components/team/SwappedTeams.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'
export default {
    name: 'LeagueInfo',
    components: { LeagueLogo, ArticleLogo, TableNews, NewsSubMenu, GoalScorers, WeeklyResults, SwappedTeams },
    emits: ['news_direction'],
    data() {
        return {
            logo: 'logo-serie-a',
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default',
            title: 'League Info',
            allyears: null
        }
    },
    methods: {
        sorting(event) {
            this.currentYear = event.target.value
        },
        years(event) {
            this.allyears = event

        }

    },
    mounted() {
        document.title = 'Serie A / Rossoneries';

    }

}
</script>

<style>
@media screen and (max-width:850px) {
    main.news ul.league_info {
        width: 90%;
        display: block;
    }

}

@media screen and (max-width: 350px) {
    main.news ul.league_info {
        width: 98%;

    }
}

ul.league_info {
    width: 90%;
    margin: 5px auto;
    display: flex;
}

ul.league_info li {
    background-color: rgb(255, 255, 255);
    margin-top: 0 !important;
    list-style: none;
    border-radius: 10px;
    padding: 0 5px;
}

.info_table {
    flex: 0 0 60%;
    padding: 0 2px;
}

.info_extra {
    flex: 0 0 40%;
    padding: 0 2px;

}

.extra_items {

    display: flex;
    width: 200px;
    padding: 5px;
    margin: 10px auto;
    margin-bottom: 0;
    border-radius: 8px;
    background-color: rgba(26, 28, 50, 0.8);
    align-items: center;
    font-size: .8em;
    justify-content: space-around;
    transition: .3s right linear;
}
</style>